import React from "react";
import PropTypes from "prop-types";

const TabItem = props => {
  return <div>{props.children}</div>;
};
TabItem.propTypes = {
  name: PropTypes.string,
};

export default TabItem;
