import React from "react";
import styles from "./styles.module.scss";
import Tab01 from "../../../assets/image/tab01.png";
import Tab02 from "../../../assets/image/tab02.png";
import Tab03 from "../../../assets/image/tab03.png";
import ApplicationMobile from "../../../assets/image/applicationsMobile.webp";
import ChatMobileEN from "../../../assets/video/ChatMobile.mp4";
import ChatMobileES from "../../../assets/video/Chat_esp_mobile.mp4";
import ChatMobilePR from "../../../assets/video/Chat_port_mobile.mp4";
import CalendarMobile from "../../../assets/video/CalendarMobile.mp4";
import LineMobile from "../../../assets/image/lineMobile.png";
import Carousel from "react-multi-carousel";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import "react-multi-carousel/lib/styles.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useOnScreen } from "../../../hooks/useOnScreen"

const responsive = {
  mobile: {
    partialVisibilityGutter: 500,
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function handleMobileVideos(val) {
  switch (val) {
    case "en":
      return ChatMobileEN;
    case "es":
      return ChatMobileES;
    case "pr":
      return ChatMobilePR;
    default:
      return ChatMobileEN;
  }
}

const CarouselItem = ({ item, index }) => {
  const { tab, title, description, image, isVideo } = item;
  const classSpacing = `item${index}`;
  const [ref, isVisible] = useOnScreen();
  
  return (
    <div className={styles.carouselItem}>      
      <div className={styles.carouselItem_tab}>
        <h2>{tab}</h2>
      </div>
      <div ref={ref} className={`${styles.carouselItem_image} ${styles[classSpacing]} `}>
        {isVideo? (
          <video
            width={600}
            height={400}
            autoPlay
            loop
            muted
            playsInline
          >
            { isVisible && <source src={image} type="video/mp4"></source> }
          </video>
        ) : (
          <img src={image} alt='title' loading="lazy"/>
        )}
      </div>
      <div className={styles.carouselItem_footer}>
        {title && <h3>{title}</h3>}
        <p style={{ color: "rgba(255, 255, 255, 0.6)" }}>{description}</p>
      </div>
    </div>
  );
};

export default function ServicesTabsMobile({ fromHome }) {
  const { t } = useTranslation();
  const { language } = useI18next();

  const OPTIONS = [
    {
      tab: t("SERVICES.FACTORY.MANAGEMENT.TAB_NAME"),
      title: t("SERVICES.FACTORY.MANAGEMENT.TITLE"),
      description: t("SERVICES.FACTORY.MANAGEMENT.SUBTITLE"),
      image: Tab01,
    },
    {
      tab: t("SERVICES.FACTORY.SCOPE.TAB_NAME"),
      title: t("SERVICES.FACTORY.SCOPE.TITLE"),
      description: t("SERVICES.FACTORY.SCOPE.SUBTITLE"),
      image: Tab02,
    },
    {
      tab: t("SERVICES.FACTORY.PARTNER.TAB_NAME"),
      title: t("SERVICES.FACTORY.PARTNER.TITLE"),
      description: t("SERVICES.FACTORY.PARTNER.SUBTITLE"),
      image: Tab03,
    },
  ];

  const OPTIONS_HOME = [
    {
      tab: t("FINANCIAL_INDUSTRY.SUITE.TAB_NAME"),
      title: "",
      description: t("FINANCIAL_INDUSTRY.SUITE.SUBTITLE"),
      image: handleMobileVideos(language),
      isVideo: true,
    },
    {
      tab: t("FINANCIAL_INDUSTRY.APPS.TAB_NAME"),
      title: "",
      description: t("FINANCIAL_INDUSTRY.APPS.SUBTITLE"),
      image: ApplicationMobile,
    },
    {
      tab: t("FINANCIAL_INDUSTRY.ALFA.TAB_NAME"),
      title: "",
      description: t("FINANCIAL_INDUSTRY.ALFA.SUBTITLE"),
      image: CalendarMobile,
      isVideo: true,
    },
  ];
  const data = fromHome ? OPTIONS_HOME : OPTIONS;
  return (
    <div className={styles.services}>
      <div className={styles.services_separation}>
        <div className={styles.arrowsContainer}>
          <FiChevronLeft className={styles.arrowLeft}/>
          <FiChevronRight className={styles.arrowRight}/>
        </div>
        <img src={LineMobile} alt='title' loading="lazy"/>
      </div>
      <Carousel responsive={responsive} showDots={false} arrows={false}>
        {data.map((el, index) => (          
          <CarouselItem item={el} index={index} />          
          ))}
      </Carousel>
    </div>
  );
}
