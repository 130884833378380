import React from "react";
import Divider from "../../../assets/svg/dividerCenter.svg";

const DividerCenter = ({ mt }) => {
  return (
    <div className='dividerCenter' style={{ marginTop: mt }}>
      <img
        className={"dividerCenter_image"}
        src={Divider}
        alt='Linea divisoria'
        loading="lazy"
      />
    </div>
  );
};

export default DividerCenter;
